<template>
  <div class="card-graph-representative">
    <div class="username-content">
      <p
        v-if="name"
        class="d-inline-block name-user"
      >
        {{ `${$t('views.home.welcome') } ${ name }` }}
      </p>
      <rectoplus-button
        id="btn-redirect-offer-credit"
        class="btn-reuse-nfes"
        :text="$t('views.home.request_quote')"
        @click="openDialog()"
      />
      <budget-request-dialog
        v-model="dialog"
        @sendBudgetSolicitation="sendBudgetSolicitation"
      />
    </div>
    <div class="content-bi">
      <div class="iframe">
        <iframe
          width="100%"
          height="1024px"
          src="https://app.powerbi.com/view?r=eyJrIjoiMTE3ZGFlMTAtMDg0YS00ZjM2LWEzZTQtMzg2ZGI4YjBjYzI2IiwidCI6IjhiODBjYzMxLTlhMmEtNDMyNy04Y2I2LTliNjliZWYxZTY5OSJ9"
          frameborder="0"
          allowFullScreen="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import RectoplusButton from '@/components/buttons/RectoplusButton.vue';
import BudgetRequestDialog from '@/components/dialogs/BudgetRequestDialog.vue';

export default {
  name: 'DashboardCamrCooperative',
  components: {
    RectoplusButton,
    BudgetRequestDialog,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    ...mapActions([
      'createBudgetSolicitation',
    ]),
    openDialog() {
      this.dialog = true;
    },
    sendBudgetSolicitation(params) {
      this.createBudgetSolicitation(params);
    },
  },
};
</script>
<style lang="scss" scoped>
.card-graph-representative {
  .username-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .name-user {
      font-size: 1.8em;
      font-weight: bold;
    }
    .btn-reuse-nfes {
      width: 312px;
      height: 60px;
    }
  }
  .content-bi {
    margin-top: 32px;
    background: $background_card_kind;
    border-radius: 10px;
    padding: 10px;
    padding-bottom: 50px;
    text-align: center;
    .iframe {
      height: 950px;
      overflow-y: hidden;
    }
  }
}
</style>
