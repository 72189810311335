<template>
  <v-dialog
    id="budget-request-dialog"
    v-model="dialog"
    :change="toggleDialog()"
    class="budget-dialog"
    max-width="600"
    transition="dialog-top-transition"
  >
    <div class="card-dialog">
      <div class="title-text">
        {{ $t('components.dialogs.budget_request.title') }}
      </div>

      <div class="text-justify">
        {{ $t('components.dialogs.budget_request.label') }}
      </div>

      <v-textarea
        v-model="descriptionMessage"
        background-color="background_textarea"
        class="textarea-budget-request"
        outlined
      />
    </div>
    <button
      class="button-dialog"
      @click="sendBudgetSolicitation"
    >
      {{ $t('components.dialogs.budget_request.send') }}
    </button>
  </v-dialog>
</template>
<script>

export default {
  name: 'BudgetRequestDialog',
  model: {
    prop: 'openDialog',
    event: 'change',
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      descriptionMessage: '',
      dialog: false,
    };
  },
  watch: {
    openDialog(value) {
      this.dialog = value;
    },
  },
  methods: {
    sendBudgetSolicitation() {
      this.$emit('sendBudgetSolicitation', { description: this.descriptionMessage });
      this.dialog = false;
    },
    toggleDialog() {
      this.$emit('change', this.dialog);
    },
    useTemplate() {
      this.descriptionMessage = this.$t('components.dialogs.budget_request.placeholder');
    },
  },
};
</script>
<style lang="scss" scoped>
.card-dialog {
  padding: 40px 40px 20px 40px;
  max-height: 450px;
  border-radius: 10px;
  background: $color_light;
  justify-content: center;
  align-items: center;
  overflow: auto;

  .title-text {
    color: $color_dark;
    font-size: 1.875em;
    margin-bottom: 30px;
    font-weight: bold;
    text-align: center;
  }
  .subtitle-text {
    text-align: center;
    color: $color_secondary;
    font-size: 1.5em;
  }

  .textarea-budget-request {
    border-radius: 10px;
    margin-top: 24px;
    width: 100%;
  }

}
.button-dialog {
  margin-top: 40px;
  border-radius: 10px;
  color: $color_light;
  font-size: 1.5em;
  font-weight: bold;
  background: $color_primary;
  height: 70px;
}
</style>
