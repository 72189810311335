<template>
  <div class="home">
    <div class="home-card">
      <dashboard-representative
        v-if="representative || industry"
        :name="nameUser"
      />
      <dashboard-logistic-operator
        v-else
        :name="nameUser"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import DashboardLogisticOperator from '@/components/dashboard/DashboardLogisticOperator.vue';
import DashboardRepresentative from '@/components/dashboard/DashboardRepresentative.vue';

export default {
  name: 'Home',

  components: {
    DashboardLogisticOperator,
    DashboardRepresentative,
  },

  computed: {
    ...mapGetters([
      'currentUser',
      'getBusiness',
    ]),
    nameUser() {
      return this.currentUser?.name;
    },
    industry() {
      return this.getBusiness.kind === 'industry_company';
    },
    representative() {
      return this.getBusiness.kind === 'representative_business_industries';
    },
    cooperativeCamr() {
      return this.getBusiness.kind === 'cooperative_association' || this.getBusiness.kind === 'waste_merchant';
    },
  },

  mounted() {
    this.fetchBusinessDocuments({ business_id: this.getBusiness.id });

    if (!this.currentUser) {
      this.$store.dispatch('refreshUser');
    }
  },

  methods: {
    ...mapActions([
      'fetchBusinessDocuments',
    ]),
  },
};
</script>
<style lang="scss" scoped>
.home {
  background: $dashboard_background;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .home-card {
    margin: 3em 2em 0 16em;
    padding-bottom: 20px;
  }
}
</style>
